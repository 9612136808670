@font-face {
  font-family: 'Diodrum-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Diodrum-Regular'),
    url('fonts/Diodrum-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Diodrum-Semibold';
  font-style: normal;
  font-weight: 600;
  src: local('Diodrum-Semibold'),
    url('fonts/Diodrum-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'Diodrum-Medium';
  font-style: normal;
  font-weight: 400;
  src: local('Diodrum-Medium'),
    url('fonts/Diodrum-Medium.otf') format('opentype');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Diodrum-Regular';
  scroll-behavior: smooth;
  /* font-size: 16px; */
  /* font-size: 14px; */
  /* color:#313131 */
}

body {
  overflow: hidden;
}

/* Thin scrollbar */
::-webkit-scrollbar {
  width: 7px;
  /* Set the width of the scrollbar */
  height: 20px;
}

::-webkit-scrollbar-thumb {
  /* Set the color of the scrollbar thumb */
  /* background-color: #349eff;  */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 4px;
  background-color: #349eff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #349eff;
}

.scrollview {
  width: 100%;
  overflow: auto;
  scroll-behavior: smooth !important;
}

.scrollview::-webkit-scrollbar-button:single-button {													   
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-radius: 5px 5px 0 0;
  height: 16px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
.scrollview::-webkit-scrollbar-button:single-button:vertical:increment {
  border-radius: 0px 0px 5px 5px;
  height: 16px;
  width: 16px;
  background-position: center 4px;

  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-radius: 5px 0px 0 5px;
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment {														 
				 
  border-radius: 0 5px 5px 0;
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.defaultMessage {
  list-style-type: disc !important;
  list-style-type: inside;
  margin-top: 15px;
  margin-left: 35px;
}

.defaultMessage li {
  margin-bottom: 10px
}

.title {
  font-size: 15px;
}

.activeItem:hover {
  background-color: #E2EDF6 !important;
  border-radius: 0px;
}

@import url("./styles/header.css");
@import url("./styles/loading.css");
@import url("./styles/antd.css");



 /* For mobile devices */
@media only screen and (max-width: 992px) {
  .leftsider,
  .rightsider {
    position: absolute !important;
    top: 55px; /* Added "px" unit */
    height: 100vh;
    transition: width 0.3s ease;
    z-index: 50;
  }

  .leftsider {
    left: 0;
  }

  .rightsider {
    right: 0;
  }

  .sourcetogglebutton {
    display: block;
  }
}

/* For tablets and larger screens */
@media only screen and (min-width: 993px) {
  /* .leftsider,
  .rightsider {
    position: static !important;
    height: auto; 
    transition: none; 
  } */

  .sourcetogglebutton {
    display: none;
  }
}
