header {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    user-select: none;
    transition: all ease-in-out 0.3s;
}

header nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    /* padding: 0px 25px; */
}

header .logo {
    /* font-size: 1.6rem;
    color: #fff;
    font-weight: 700; */
    padding-top: 7px;
    margin-left: 7px;
}

header ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
}

header ul li {
    font-size: 0.9rem;
    margin: 0 12px;
    transition: all ease 0.3s;
    text-transform: capitalize;
}

header ul li a {
    color: #fff;
    display: block;
}

header ul li a:is(:hover, .active) {
    color: #fff;
}

header nav .menu_btn {
    font-size: 1.8rem;
    cursor: pointer;
    display: none
}

@media (max-width: 850px) {
    header ul {
        position: absolute;
        top: 100%;
        left: 0;
        background: #e4001c;
        display: block;
        width: 100%;
        /* box-shadow: 2px 20px 30px white; */
        transition: all .3s ease-in-out;
        height: 0;
        overflow: hidden;
        list-style-type: none;
    }

    header ul li {
        display: block;
        padding: 0px 15px;
    }

    header nav {
        padding: 0px 0px;
    }

    header nav .menu_btn {
        display: block
    }
}