/* Apply your custom font to all Ant Design components */
.ant-typography,
.ant-btn,
.ant-input,
.ant-select,
.ant-checkbox,
.ant-radio,
.ant-switch,
.ant-table,
.ant-dropdown,
.ant-menu,
.ant-modal,
.ant-notification,
.ant-message,
.ant-tree,
.ant-image,
.ant-form {
  font-family: 'Diodrum-Regular';
  font-size: 16px;
}

.ant-input {
  color: #313131;
}

.ant-typography strong {
  font-family: 'Diodrum-Semibold';
}

/* .custom-menu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 10px;
  right: initial;
} */

.ant-menu-submenu-title {
  padding-left: 10px !important;
}

.ant-menu-item {
  padding-left: 20px !important;  
  width: calc(100% - 5px) !important;  
}

.faqmenuList .ant-menu-item-group-title {
  padding: 0px !important;
}

.ant-layout-sider-zero-width-trigger {
  background-color: #313131 !important;
}

.ant-menu-submenu-title:hover {
  background-color: #E2EDF6 !important;
  border-radius: 0px;
}

.ant-menu-item-only-child {
  width: 97% !important;
}